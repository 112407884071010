import styles from './Footer.module.css';
import helperStyles from './Helpers.module.css';
import Separator2 from '../public/separators/line2.svg';

const Component: React.FC = () => (
  <footer className={styles.footer}>
    <div className={styles.footerContent}>
      <div
        style={{
          paddingTop: '40px',
        }}
        className={`${helperStyles.limitWidth} ${styles.separatorWrapper}`}
      >
        <Separator2 />
      </div>

      <div style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '15px 0',
      }}
      >
        <a
          href="https://www.youtube.com/channel/UCjNOBm_KPPCVXe2yFe2M0PQ"
          className={`${helperStyles.anchorNoStyle} ${styles.link}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-youtube" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="3" y="5" width="18" height="14" rx="4" />
            <path d="M10 9l5 3l-5 3z" />
          </svg>
        </a>

        <a
          href="https://www.facebook.com/ashourguitar"
          className={`${helperStyles.anchorNoStyle} ${styles.link}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-facebook" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
          </svg>
        </a>

        <a
          href="https://www.instagram.com/ashourguitar/"
          className={`${helperStyles.anchorNoStyle} ${styles.link}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-instagram" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="4" y="4" width="16" height="16" rx="4" />
            <circle cx="12" cy="12" r="3" />
            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
          </svg>
        </a>
      </div>

      <p style={{
        fontSize: '10px',
        opacity: 0.75,
        textAlign: 'center',
        direction: 'ltr',
      }}
      >
        ©
        {new Date().getFullYear()}
        {' '}
        Ashour Guitar. All rights reserved.
      </p>
      <p style={{
        fontSize: '10px',
        opacity: 0.4,
        textAlign: 'center',
        margin: '0 0 10px 0',
        direction: 'ltr',
      }}
      >

        Made by
        {' '}
        <a href="mailto:omarryhan@gmail.com">
          Omar Ryhan
        </a>
      </p>
    </div>
  </footer>
);

export default Component;
